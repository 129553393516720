<template>
    <div>
        <v-dialog v-model="appointmentDialog" transition="slide-x-transition">
            <v-form ref="formAppointment" lazy-validation>
                <v-card flat class="right-side-dialog">
                    <v-toolbar>
                        <v-card-title class="headline">
                            <span class="primary--text-kh">បំពេញព័ត៌មានតំណាងអយ្យការ</span>
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn text fab dark large color="primary" @click="appointmentDialog=false">
                            <v-icon>{{icons.mdiClose}}</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <br />
                    <v-card-text>
                        <center>
                            <v-icon color="primary" class="mb-5" size="50">{{icons.mdiReceiptTextEdit}}</v-icon>
                            <h3 class="text-h6 mb-6">
                                <span class="primary--text"> ឈ្មោះក្រឡាបញ្ជី៖ {{clerkName}}</span>
                            </h3>
                        </center>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="appointment.letterNo" label="លេខលិខិត" outlined dense hide-details :rules="rule.requiredField()">
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                                <v-text-field v-model="appointment.appointmentDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <!--
                                <v-text-field v-model="appointment.prosecutorName" label="ឈ្មោះតំណាងអយ្យការ" outlined dense hide-details :rules="rule.requiredField()">
                                </v-text-field>
                                -->
                                <v-select v-model="appointment.staff" outlined label="តំណាងអយ្យការ" :items="prosecutorList" return-object :rules="rule.requiredField()" hide-details dense>
                                    <template slot="selection" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.nameKh}}
                                        </span>
                                        <span v-else>{{data.item.nameEn}}</span>
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <span v-if="$t('lang') == 'KH'">
                                            {{data.item.nameKh}}
                                        </span>
                                        <span v-else>{{data.item.nameEn}}</span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="clerkName" label="ឈ្មោះក្រឡាបញ្ជី" readonly outlined dense hide-details></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="flex text-center justify-center">
                        <v-spacer></v-spacer>
                        <v-btn class="btn primary flex-grow-1" rounded @click="createAppointment">
                            <v-icon dark left>{{icons.mdiContentSave}}</v-icon>
                            {{$t('button.approve')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <div v-if="appointmentList.length > 0" class="flex">
            <v-card-title>
                <span class="primary--text">ការចាត់តាំងតំណាងអយ្យការ</span>
            </v-card-title>
            <v-card-text>
                <v-data-table dense class="borderless-table elevation-1" :headers="appointmentHeader" :items="appointmentList" :hide-default-footer="true" :show-rows-border="false">
                    <template v-slot:[`item.staff`]="{ item }">
                        <span v-if="item.staff != null">{{item.staff.nameKh}}</span>
                    </template>
                </v-data-table>
            </v-card-text>
        </div>
        <div class="flex text-center my-5">
            <v-btn class="primary--text" rounded outlined @click="appointmentDialog=true">
                <v-icon dark left>{{icons.mdiPlus}}</v-icon> ចាត់តាំងតំណាងអយ្យការ
            </v-btn>
        </div>
        <div v-if="appointmentList.length > 0">
            <v-tabs v-model="prosTab" fixed-tabs dark slider-color="#fff">
                <v-tab :value="0">ការកោះហៅ</v-tab>
                <v-tab :value="1">ឯកសារមេធាវីការពារក្តី</v-tab>
                <v-tab :value="2">ជនទាក់ទិន</v-tab>
                <v-tab :value="3">ភស្តុតាង</v-tab>
                <v-tab :value="4">កិច្ចការបានអនុវត្ត</v-tab>
                <v-tab :value="5">ការសម្រេចរបស់អយ្យការ</v-tab>
            </v-tabs>
            <v-window v-model="prosTab">
                <v-window-item key="0">
                    <v-card-title>
                        <span class="primary--text">ការកោះហៅ</span>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="warrantDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :headers="warrantHeader" :items="warrantList">
                            <template v-slot:[`item.no`]="{ item }">
                                {{warrantList.indexOf(item)+1}}
                            </template>
                            <template v-slot:[`item.appearanceDate`]="{ item }">
                                {{item.appearanceDate}}
                                <b>{{item.appearanceTime}} </b>
                            </template>

                            <template v-slot:[`item.parties`]="{ item }">
                                <v-chip v-for="party in item.parties" :key="party.id" class="ma-1">
                                    {{party.nameKh}}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">

                                <v-icon medium color="primary" class="mr-2" @click="editWarrant(item)">{{icons.mdiReceiptTextEdit}}</v-icon>

                            </template>
                        </v-data-table>

                        <v-dialog v-model="warrantDialog" transition="slide-x-transition" @input="onWarrantDialogClose">
                            <v-card class="right-side-dialog-800">
                                <v-toolbar flat>
                                    <v-card-title>
                                        <span class="primary--text-kh">បញ្ចូលការកោះហៅ</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="onWarrantDialogClose">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text class="mt-5">
                                    <v-form ref="formWarrant" lazy-validation>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="warrantData.warrantLetterNo" label="លេខដីកាកោះ" outlined dense hide-details>
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="warrantData.warrantDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="warrantData.prosecutorName" label="ឈ្មោះតំណាងអយ្យការ" outlined dense hide-details>
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="clerkName" label="ឈ្មោះក្រឡាបញ្ជី" readonly outlined dense hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-select v-model="warrantData.letterType" outlined label="ប្រភេទដីកា" :items="getCaseLetterTypeByTypeId(2)" return-object :rules="rule.requiredField()" hide-details dense>
                                                    <template slot="selection" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.letterNameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.letterNameEn}}</span>
                                                    </template>
                                                    <template slot="item" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.letterNameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.letterNameEn}}</span>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field v-model="warrantData.appearanceDate" label="កាលបរិច្ឆេទបង្ហាញខ្លួន" outlined dense type="date" hide-details></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field v-model="warrantData.appearanceTime" outlined hide-details dense pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" type="time" label="ម៉ោង">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select v-model="warrantData.parties" outlined label="ភ្ជាប់ឈ្មោះជនទាក់ទិន" :items="relatedPersonList" item-text="nameKh" return-object dense clearable chips multiple hide-details>
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip small>{{ item.nameKh }}</v-chip>
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-action>
                                                                <v-checkbox :input-value="active"></v-checkbox>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center">
                                                                        <span>{{ item.nameKh }}</span>
                                                                        <v-spacer></v-spacer>
                                                                        <v-chip small>{{ item.partyRole.roleNameKh }}</v-chip>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="btn primary flex-grow-1" rounded @click="createWarrant">{{$t('button.save')}}</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-card-text>
                </v-window-item>
                <v-window-item key="1">
                    <v-card-title>
                        <span class="primary--text">ឯកសារមេធាវីការពារក្តី</span>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="documentDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :headers="documentHeader" :items="documentList">
                            <template v-slot:[`item.no`]="{ item, index }">
                                {{index+1}}
                            </template>
                            <template v-slot:[`item.caseLetterType`]="{ item }">
                                <span v-if="item.caseLetterType != null">
                                    {{item.caseLetterType.letterNameKh}}
                                </span>
                            </template>
                            <template v-slot:[`item.parties`]="{ item }">
                                <v-chip class="ma-1" v-if="item.parties.length > 0" v-for="party in item.parties" :key="party.id">
                                    {{party.nameKh}}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.lawyerInfo`]="{ item }">
                                <v-btn class="btn" small rounded outlined @click="viewLawyer(item)">
                                    {{$t('button.lawyerInfo')}}
                                </v-btn>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">

                                <v-icon medium color="primary" class="mr-2" @click="editDocument(item)">{{icons.mdiReceiptTextEdit}}</v-icon>

                            </template>
                        </v-data-table>
                        <v-dialog v-model="documentLawyerDialog" max-width="800">
                            <v-card min-height="400">
                                <v-toolbar flat>
                                    <v-card-title class="headline">
                                        <span class="primary--text-kh">ឈ្មោះមេធាវី</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="documentLawyerDialog = false">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text class="mt-5">
                                    <v-simple-table class="borderless-table elevation-1">
                                        <tr>
                                            <th>ឈ្មោះមេធាវី</th>
                                            <th>អត្តលេខ</th>
                                            <th>ការិយាល័យ</th>
                                            <th>លេខទូរស័ព្ទ</th>
                                            <th>ប្រភេទមេធាវី</th>
                                        </tr>
                                        <tr style="text-align:center;" v-for="item in documentLawyerList" :key="item.id">
                                            <td style="padding: 10px">{{item.lawyerNameKh}}</td>
                                            <td style="padding: 10px">{{item.lawyerCardNo}}</td>
                                            <td style="padding: 10px">{{item.lawyerOffice}}</td>
                                            <td style="padding: 10px">{{item.lawyerPhone}}</td>
                                            <td style="padding: 10px">
                                                <span v-if="item.lawyerType">{{item.lawyerType.nameKh}}</span>
                                            </td>
                                        </tr>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>

                        </v-dialog>
                        <v-dialog v-model="documentDialog" transition="slide-x-transition" @input="onDocumentDialogClose">
                            <v-form ref="formDocument" lazy-validation>
                                <v-card class="right-side-dialog">
                                    <v-toolbar flat>
                                        <v-card-title>
                                            <span class="primary--text-kh">បញ្ចូលឯកសារមេធាវី</span>
                                        </v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-btn text fab dark large color="primary" @click="onDocumentDialogClose">
                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <v-card-text class="mt-5">
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="lawyerDocument.documentNo" label="លេខលិខិត" outlined dense hide-details>
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="lawyerDocument.documentDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-select v-model="lawyerDocument.caseLetterType" outlined :label="$t('select.letterType')" :items="getCaseLetterTypeByTypeId(1)" return-object :rules="rule.requiredField()" hide-details dense>
                                                    <template slot="selection" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.letterNameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.letterNameEn}}</span>
                                                    </template>
                                                    <template slot="item" slot-scope="data">
                                                        <span v-if="$t('lang') == 'KH'">
                                                            {{data.item.letterNameKh}}
                                                        </span>
                                                        <span v-else>{{data.item.letterNameEn}}</span>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-dialog v-model="lawyerListDialog" max-width="100%">
                                                <v-card flat style="min-height:300px">
                                                    <v-toolbar flat>
                                                        <v-card-title class="headline">
                                                            <span class="primary--text-kh">ភ្ជាប់ឈ្មោះមេធាវី</span>
                                                        </v-card-title>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text fab dark large color="primary" @click="lawyerListDialog=false">
                                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                                        </v-btn>
                                                    </v-toolbar>
                                                    <v-divider></v-divider>
                                                    <v-card-text class="mt-5">
                                                        <v-row v-for="(item, index) in lawyerData.lawyers" :key="item.id">
                                                            <v-col cols="12" md="2">
                                                                <v-text-field v-model="item.lawyerNameKh" label="ឈ្មោះមេធាវី" outlined dense hide-details :rules="rule.requiredField()"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <v-text-field v-model="item.lawyerCardNo" label="អត្តលេខ" outlined dense hide-details></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="3">
                                                                <v-text-field v-model="item.lawyerOffice" label="ការិយាល័យ " outlined dense hide-details></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <v-text-field v-model="item.lawyerPhone" label="លេខទូរស័ព្ទ" outlined dense hide-details></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="2">
                                                                <v-select v-model="item.lawyerType" label="ប្រភេទមេធាវី" outlined dense :items="caseLawyerTypes" item-text="nameKh" return-object hide-details>
                                                                    <template slot="selection" slot-scope="data">
                                                                        <span v-if="$t('lang') == 'KH'">
                                                                            {{data.item.nameKh}}
                                                                        </span>
                                                                        <span v-else>{{data.item.nameEn}}</span>
                                                                    </template>
                                                                    <template slot="item" slot-scope="data">
                                                                        <span v-if="$t('lang') == 'KH'">
                                                                            {{data.item.nameKh}}
                                                                        </span>
                                                                        <span v-else>{{data.item.nameEn}}</span>
                                                                    </template>
                                                                </v-select>
                                                            </v-col>
                                                            <v-col cols="12" sm="1">
                                                                <v-icon large color="primary" @click="removeLawyerItem(index)">{{icons.mdiDelete}}</v-icon>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="mt-5 justify-center">
                                                            <v-col cols="4" align="center">
                                                                <v-btn class="btn primary" @click="addLawyerItem($event)" small rounded>
                                                                    <v-icon small>{{icons.mdiPlus}}</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-dialog>
                                            <v-col cols="12" md="6">
                                                <v-btn class="primary--text" rounded outlined @click="lawyerListDialog=true">
                                                    <v-icon dark left>{{icons.mdiAttachment}}</v-icon> ភ្ជាប់ឈ្មោះមេធាវី
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-select v-model="lawyerDocument.parties" outlined label="ភ្ជាប់ឈ្មោះជនទាក់ទិន" :items="relatedPersonList" :item-text="getFieldText" dense return-object clearable chips multiple hide-details>
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip small>{{ item.nameKh }}</v-chip>
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-action>
                                                                <v-checkbox :input-value="active"></v-checkbox>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center">
                                                                        <span>{{ item.nameKh }}</span>
                                                                        <v-spacer></v-spacer>
                                                                        <v-chip small>
                                                                            {{ item.partyRole.roleNameKh }}
                                                                        </v-chip>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="btn primary flex-grow-1" rounded @click="createDocument">{{$t('button.save')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                    </v-card-text>
                </v-window-item>
                <v-window-item key="2">
                    <v-card-title>
                        <span class="primary--text">ជនទាក់ទិន</span>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="relatedPersonListDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                            <!--បន្ថែមជនទាក់ទិន-->
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :items="relatedPersonList" :headers="partyListHeader">
                            <template v-slot:[`item.no`]="{ item }">
                                {{relatedPersonList.indexOf(item)+1}}
                            </template>
                            <template v-slot:[`item.gender`]="{ item }">
                                <span v-if="item.gender == 1">ស្រី</span>
                                <span v-else>ប្រុស</span>
                            </template>
                            <template v-slot:[`item.partyRole`]="{ item }">
                                {{item.partyRole.roleNameKh}}
                            </template>
                        </v-data-table>
                        <v-dialog v-model="relatedPersonListDialog" transition="slide-x-transition" @input="onRelatedPartyClose">
                            <v-form ref="formParties" lazy-validation>
                                <v-card class="right-side-dialog-800">
                                    <v-toolbar flat>
                                        <v-card-title class="headline">
                                            <span class="primary--text-kh">{{$t('case.addRelatedPerson')}}</span>
                                        </v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-btn text fab dark large color="primary" @click="onRelatedPartyClose">
                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <v-card-text class="mt-5">
                                        <div v-for="(item,index) in relatedPersonList" :key="item.id">
                                            <v-row>
                                                <v-col cols="12" md="3">
                                                    <v-text-field v-model="item.nameKh" outlined hide-details :label="$t('case.relatedPersonName')" :rules="rule.requiredField()" dense>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-radio-group row v-model="item.gender" class="mt-0 ml-2">
                                                        <v-radio :label="$t('text.male')" :value=0></v-radio>
                                                        <v-radio :label="$t('text.female')" :value=1></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <v-text-field v-model="item.age" outlined hide-details :label="$t('text.age')" dense>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-select v-model="item.partyRole" :items="relatedPersonType" label="ជ្រើសរើសតួនាទីភាគី" return-object item-text="roleNameKh" dense hide-details outlined>
                                                        <template v-slot:selection="data">
                                                            {{data.item.roleNameKh}}
                                                        </template>
                                                        <template v-slot:item="data">
                                                            {{data.item.roleNameKh}}
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12" md="1">
                                                    <v-icon class="mr-5" large color="primary" @click="removeRelatedPerson(index)">{{icons.mdiDelete}}</v-icon>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-row class="mt-5 justify-center">
                                            <v-col cols="4" align="center">
                                                <v-btn class="btn primary--text" outlined @click="addRelatedPerson($event)" small rounded>
                                                    <v-icon small>{{icons.mdiPlus}}</v-icon> បន្ថែម
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="btn primary flex-grow-1" rounded @click="createParties">{{$t('button.save')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                    </v-card-text>
                </v-window-item>
                <v-window-item key="3">
                    <v-card-title>
                        <span class="primary--text">ភស្តុតាង</span>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="evidenceListDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :items="evidenceDocumentList" :headers="evidenceHeader">
                            <template v-slot:[`item.no`]="{ index, item }">
                                {{index+1}}
                            </template>
                            <template v-slot:[`item.deliveryStatus`]="{ item }">
                                <span v-if="item.deliveryStatus == 1">បានបញ្ជូន</span>
                                <span v-else>បានបញ្ជូន</span>
                            </template>
                            <template v-slot:[`item.parties`]="{ item }">
                                <v-chip class="ma-1" v-if="item.parties.length > 0" v-for="party in item.parties" :key="party.id">
                                    {{party.nameKh}}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.view`]="{ item }">

                                <v-icon medium color="primary" class="mr-2" @click="viewEvidence(item)">{{icons.mdiEye}}</v-icon>

                                <v-icon medium color="primary" class="mr-2" @click="editEvidence(item)">{{icons.mdiReceiptTextEdit}}</v-icon>

                            </template>
                        </v-data-table>
                        <v-dialog v-model="evidenceItemDialog" transition="slide-x-transition">
                            <v-card class="right-side-dialog-800">
                                <v-toolbar flat>
                                    <v-card-title class="headline">
                                        <span class="primary--text-kh">{{$t('case.evidenceList')}}</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="evidenceItemDialog=false">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text v-if="evidenceItems != null">
                                    <br />
                                    <v-simple-table>
                                        <tr>
                                            <th>ល.រ</th>
                                            <th>ប្រភេទវត្ថុ</th>
                                            <th>ឈ្មោះវត្ថុ</th>
                                            <th>ការពិពណ៌នា</th>
                                        </tr>
                                        <tr style="text-align:center" v-for="(item, index) in evidenceItems" :key="item.id">
                                            <td>{{index + 1}}</td>
                                            <td>{{item.evidenceType.evidenceTypeNameKh}}</td>
                                            <td>{{item.nameKh}}</td>
                                            <td>{{item.description}}</td>
                                        </tr>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="evidenceListDialog" transition="slide-x-transition" @input="onEvidenceDialogClose">
                            <v-form ref="formEvidenceList" lazy-validation>
                                <v-card class="right-side-dialog-800">
                                    <v-toolbar flat>
                                        <v-card-title class="headline">
                                            <span class="primary--text-kh">{{$t('case.addEvidence')}}</span>
                                        </v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-btn text fab dark large color="primary" @click="onEvidenceDialogClose">
                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="evidenceDocument.documentNo" label="លេខដីកា" outlined dense hide-details :rules="rule.requiredField()">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="evidenceDocument.documentDate" label="កាលបរិច្ឆេទ" outlined dense hide-details type="date"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="3">
                                                <v-btn class="primary--text" rounded outlined @click="evidenceDialog=true">
                                                    <v-icon dark left>{{icons.mdiAttachment}}</v-icon> ភ្ជាប់ភស្តុតាង
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12" md="9">
                                                <v-select v-model="evidenceDocument.parties" outlined label="ចាប់យកពីអ្នកណា (ជនទាក់ទិន)" :items="relatedPersonList" item-text="nameKh" dense return-object clearable chips multiple rounded hide-details>
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip small>{{ item.nameKh }}</v-chip>
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-action>
                                                                <v-checkbox :input-value="active"></v-checkbox>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center">
                                                                        <span>{{ item.nameKh }}</span>
                                                                        <v-spacer></v-spacer>
                                                                        <v-chip small>{{ item.partyRole.roleNameKh }}</v-chip>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-radio-group row v-model="evidenceDocument.deliveryStatus" class="mt-0 ml-2">
                                                    <v-radio label="បានបញ្ជូន" :value=1></v-radio>
                                                    <v-radio label="មិនបានបញ្ជូន" :value=0></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="btn primary flex-grow-1" color="primary" rounded @click="createEvidenceDocument">{{$t('button.save')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>

                        <v-dialog v-model="evidenceDialog" max-width="80%">
                            <v-card style="min-height:300px">
                                <v-toolbar flat>
                                    <v-toolbar-title>
                                        <span class="primary--text-kh">ភ្ជាប់វត្ថុតាង</span>
                                    </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="evidenceDialog=false">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text class="mt-5">
                                    <v-row v-for="(item, index) in evidenceData.evidenceList" :key="item.id">
                                        <v-col cols="12" md="3">
                                            <v-select v-model="item.evidenceType" :items="caseEvidenceTypeList" outlined dense hide-details return-object label="ប្រភេទភស្តុតាង">
                                                <template v-slot:selection="data">
                                                    {{data.item.evidenceTypeNameKh}}
                                                </template>
                                                <template v-slot:item="data">
                                                    {{data.item.evidenceTypeNameKh}}
                                                </template>

                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="item.nameKh" label="ឈ្មោះភស្តុតាង" outlined dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="item.description" label="ការពណ៍នានៃភស្តុតាង" outlined dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="1">
                                            <v-icon class="mr-5" large color="primary" @click="removeEvidenceItem(index)">{{icons.mdiDelete}}</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="flex text-center justify-center">
                                    <v-btn class="btn primary" @click="addEvidenceItem($event)" small rounded>
                                        <v-icon small>{{icons.mdiPlus}}</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                    </v-card-text>
                </v-window-item>
                <v-window-item key="4">
                    <v-card-title>
                        <span class="primary--text">កិច្ចការបានអនុវត្ត</span>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="taskDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :headers="taskListHeader" :items="caseTaskList">
                            <template v-slot:[`item.no`]="{ index, item }">
                                {{index+1}}
                            </template>
                            <template v-slot:[`item.taskType`]="{ item }">
                                <span v-if="item.taskType"> {{item.taskType.taskTypeKh}} </span>
                            </template>
                            <template v-slot:[`item.parties`]="{ item }">
                                <v-chip class="ma-1" v-if="item.parties.length > 0" v-for="party in item.parties" :key="party.id">
                                    {{party.nameKh}}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon medium color="primary" class="mr-2" @click="editTaskDocument(item)">{{icons.mdiReceiptTextEdit}}</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-dialog v-model="taskDialog" transition="slide-x-transition" @input="onTaskDialogClose">
                        <v-form ref="formTaskList" lazy-validation>
                            <v-card class="right-side-dialog-800">
                                <v-toolbar flat>
                                    <v-card-title class="headline">
                                        <span class="primary--text-kh">កិច្ចការបានអនុវត្ត</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="onTaskDialogClose">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="taskDocument.documentNo" label="លេខឯកសារ" outlined dense hide-details></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="taskDocument.documentDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-select v-model="taskDocument.taskType" outlined label="ប្រភេទកិច្ចការ" :items="taskTypeList" return-object :rules="rule.requiredField()" hide-details dense>
                                                <template slot="selection" slot-scope="data">
                                                    <span v-if="$t('lang') == 'KH'">
                                                        {{data.item.taskTypeKh}}
                                                    </span>
                                                    <span v-else>{{data.item.taskTypeEn}}</span>
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    <span v-if="$t('lang') == 'KH'">
                                                        {{data.item.taskTypeKh}}
                                                    </span>
                                                    <span v-else>{{data.item.taskTypeEn}}</span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select v-model="taskDocument.parties" outlined label="ភ្ជាប់ឈ្មោះជនទាក់ទិន" :items="relatedPersonList" item-text="nameKh" return-object dense clearable chips multiple hide-details>
                                                <template v-slot:selection="{ item, index }">
                                                    <v-chip small>{{ item.nameKh }}</v-chip>
                                                </template>
                                                <template v-slot:item="{ active, item, attrs, on }">
                                                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                        <v-list-item-action>
                                                            <v-checkbox :input-value="active"></v-checkbox>
                                                        </v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <v-row no-gutters align="center">
                                                                    <span>{{ item.nameKh }}</span>
                                                                    <v-spacer></v-spacer>
                                                                    <v-chip small>{{ item.partyRole.roleNameKh }}</v-chip>
                                                                </v-row>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea rows="4" auto-grow outlined v-model="taskDocument.description" label="ខ្លឹមសារសង្ខេប"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="btn primary flex-grow-1" rounded @click="createTask">{{$t('button.save')}}</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                </v-window-item>
                <v-window-item key="5">
                    <v-card-title>
                        <span class="primary--text">ការសម្រេចរបស់អយ្យការ</span>
                        <v-spacer></v-spacer>
                        <v-btn small rounded outlined class="mr-5 primary--text" @click="decisionDocumentDialog = true">
                            <v-icon>{{icons.mdiPlus}}</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table class="borderless-table elevation-1" :items="decisionDocumentList" :headers="decisionDocumentHeader">
                            <template v-slot:[`item.no`]="{ index, item }">
                                {{index+1}}
                            </template>
                            <template v-slot:[`item.isAppealed`]="{ item }">
                                <span v-if="item.isAppealed == 1">មាន</span>
                                <span v-else>មិនមាន</span>
                            </template>
                            <template v-slot:[`item.decisionType`]="{ item }">
                                {{item.decisionType.decisionTypeNameKh}}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon medium color="primary" class="mr-2" @click="showJudgement(item)">{{icons.mdiEye}}</v-icon>
                                <v-icon medium color="primary" class="mr-2" @click="editDecisionDocument(item)">{{icons.mdiReceiptTextEdit}}</v-icon>
                            </template>
                        </v-data-table>
                        <v-dialog v-model="viewJudgementDialog" transition="slide-x-transition" @input="onViewJudgementDialogClose">
                            <v-card class="right-side-dialog-800">
                                <v-toolbar flat>
                                    <v-card-title class="headline">
                                        <span class="primary--text-kh">សម្រេចចោទប្រកាន់</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="onViewJudgementDialogClose">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text class="mt-5">
                                    <v-simple-table v-if="chargePersonList != null" class="bordered-table elevation-1">
                                        <tr>
                                            <th>ឈ្មោះជនជាប់ចោទ</th>
                                            <th>បទល្មើសនៃការចោទ</th>
                                        </tr>
                                        <tr style="text-align:center" v-for="item in chargePersonList" :key="item.id">
                                            <!--     <td v-if="index >= 1 && (item.party[index].id != item.party[index - 1].id)"></td> -->
                                            <td>{{item.party.nameKh}}</td>
                                            <td>{{item.crimeType.crimeTypeNameKh}}</td>
                                        </tr>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="decisionDocumentDialog" transition="slide-x-transition" @input="onDecisionDocumentDialogClose">
                            <v-form ref="formDecisionDocumentList" lazy-validation>
                                <v-card class="right-side-dialog-800">
                                    <v-toolbar flat>
                                        <v-card-title class="headline">
                                            <span class="primary--text-kh">{{$t('case.addDecision')}}</span>
                                        </v-card-title>
                                        <v-spacer></v-spacer>
                                        <v-btn text fab dark large color="primary" @click="onDecisionDocumentDialogClose">
                                            <v-icon>{{icons.mdiClose}}</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="decisionDocument.decisionRef" label="លេខលិខិត" :rules="rule.requiredField()" outlined dense hide-details>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="decisionDocument.decisionDate" label="កាលបរិច្ឆេទ" outlined dense type="date" hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-select v-model="decisionDocument.decisionType" :items="caseDecisionTypeList" return-object item-text="decisionTypeNameKh" label="ប្រភេទសេចក្តីសម្រេច" hide-details outlined dense>
                                                    <template v-slot:selection="{ item, index }">
                                                        {{ item.decisionTypeNameKh }}
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center" style="padding: 5px">
                                                                        <span>{{ item.decisionTypeNameKh }}</span>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="decisionDocument.decisionNote" label="ការសម្រេចដូចម្តេច" outlined dense hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-radio-group row v-model="decisionDocument.isAppealed" class="mt-0 ml-2">
                                                    <v-radio label="មានបណ្តឹងឧទ្ធរណ៍" :value="true"></v-radio>
                                                    <v-radio label="មិនមាន" :value="false"></v-radio>
                                                </v-radio-group>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-btn class="primary--text" rounded outlined @click="openChargeDialog">
                                                    <v-icon dark left>{{icons.mdiAttachment}}</v-icon> ភ្ជាប់ឈ្មោះជនទាក់ទិន
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn class="btn primary flex-grow-1" rounded @click="createDecision">{{$t('button.save')}}</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                        <v-dialog v-model="chargeDialog" max-width="80%">
                            <v-card>
                                <v-toolbar flat>
                                    <v-card-title class="headline">
                                        <span class="primary--text-kh">សម្រេចចោទប្រកាន់</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn text fab dark large color="primary" @click="chargeDialog=false">
                                        <v-icon>{{icons.mdiClose}}</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <br />
                                <v-card-text>
                                    <div v-for="(item,index) in chargePersonList" :key="item.id">
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-select v-model="item.party" :items="relatedPersonList" item-text="name" return-object label="ជ្រើសរើសអ្នកសម្រេចត្រូវចោទ" hide-details outlined>
                                                    <template v-slot:selection="{ item, index }">
                                                        {{ item.nameKh }}
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center">
                                                                        <span>{{ item.nameKh }}</span>
                                                                        <v-spacer></v-spacer>
                                                                        <v-chip small>{{ item.partyRole.roleNameKh }}</v-chip>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-select :items="caseCrimeTypeList" v-model="item.crimeType" item-text="crimeTypeNameKh" label="ជ្រើសរើសបទល្មើសនៃការចោទ" return-object outlined hide-details multiple>
                                                    <template v-slot:selection="{ item, index }">
                                                        <v-chip small>{{ item.crimeTypeNameKh }}</v-chip>
                                                    </template>
                                                    <template v-slot:item="{ active, item, attrs, on }">
                                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                                            <v-list-item-action>
                                                                <v-checkbox :input-value="active"></v-checkbox>
                                                            </v-list-item-action>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-row no-gutters align="center">
                                                                        <span>{{ item.crimeTypeNameKh }}</span>
                                                                    </v-row>
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-icon class="mr-5" large color="primary" @click="removeChargePersonItem(index)">{{icons.mdiDelete}}</v-icon>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card-text>
                                <v-card-actions class="flex text-center justify-center">
                                    <v-btn class="btn primary" @click="addChargePersonItem($event)" small rounded>
                                        <v-icon small>{{icons.mdiPlus}}</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card-text>
                </v-window-item>
            </v-window>
        </div>
    </div>
</template>

<script>
import {
  mdiCalendarAlert,
  mdiPlus,
  mdiAttachment,
  mdiContentSave,
  mdiDelete,
  mdiClose,
  mdiEye,
  mdiEyeCircle,
  mdiFileEdit,
  mdiReceiptTextEdit
} from '@mdi/js'
import SystemService from '@/services/service.system'
import CaseService from '@/services/service.case'
import StaffService from '@/services/service.staff'
import Rule from '@/plugins/rules.js'

export default {
  props: {
    data: Object
  },
  name: 'prosecution-phase-section',
  data() {
    return {
      icons: {
        mdiCalendarAlert,
        mdiPlus,
        mdiAttachment,
        mdiContentSave,
        mdiDelete,
        mdiClose,
        mdiEye,
        mdiEyeCircle,
        mdiFileEdit,
        mdiReceiptTextEdit
      },
      rule: '',
      prosTab: 0,
      taskDocument: {},
      taskTypeList: [],
      editedTaskIndex: -1,
      editedDecisionIndex: -1,
      editedWarrantIndex: -1,
      editedDocumentIndex: -1,
      editedEvidenceDocumentIndex: -1,
      isProsecutorAppointed: false,
      appointmentDialog: false,
      warrantDialog: false,
      documentDialog: false,
      documentLawyerDialog: false,
      documentLawyerList: [],
      appointment: {},
      appointmentList: [],
      documentList: [],
      lawyerDocument: {},
      lawyerData: { lawyers: [] },
      warrantData: {},
      warrantList: [],
      verdictData: {},
      decisionDocument: {},
      decisionDocumentList: [],
      evidenceDocument: {},
      evidenceData: { evidenceList: [] },
      clerkName: '',
      evidenceDocumentList: [],
      caseLetterTypeList: [],
      lawyerListDialog: false,
      evidenceDialog: false,
      chargeDialog: false,
      evidenceListDialog: false,
      evidenceItemDialog: false,
      decisionDocumentDialog: false,
      viewJudgementDialog: false,
      taskDialog: false,
      evidenceItems: [],
      chargePersonList: [],
      caseCrimeTypeList: [],
      caseDecisionTypeList: [],
      criminalCase: {},
      relatedPersonList: [],
      relatedPersonType: [],
      relatedPersonListDialog: false,
      caseEvidenceTypeList: [],
      caseTaskList: [],
      caseLawyerTypes: [],
      prosecutorList: [],
      resultMessage: '',
      warrantHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'warrantLetterNo' },
        { text: 'ដីការចេញ', value: 'warrantDate' },
        { text: 'បង្ហាញខ្លួន', value: 'appearanceDate' },
        { text: 'ឈ្មោះអយ្យការ', value: 'prosecutorName' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'កែប្រែ', value: 'actions' }
      ],
      appointmentHeader: [
        { text: 'លេខលិខិត', value: 'letterNo' },
        { text: 'កាលបរិច្ឆេទ', value: 'appointmentDate' },
        { text: 'តំណាងអយ្យការ', value: 'staff' },
        { text: 'ក្រឡាបញ្ជី', value: 'clerkName' }
      ],
      partyListHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'ឈ្មោះ', value: 'nameKh' },
        { text: 'ភេទ', value: 'gender' },
        { text: 'អាយុ', value: 'age' },
        { text: 'ភាគី', value: 'partyRole' }
      ],
      documentHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'documentNo' },
        { text: 'កាលបរិច្ឆេទ', value: 'documentDate' },
        { text: 'ប្រភេទលិខិត', value: 'caseLetterType' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'ព័ត៍មានមេធាវី', value: 'lawyerInfo' },
        { text: 'កែប្រែ', value: 'actions' }
      ],
      evidenceHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'ប្រភេទ', value: 'documentNo' },
        { text: 'ឈ្មោះ', value: 'documentDate' },
        { text: 'ស្ថានភាព', value: 'deliveryStatus' },
        { text: 'ភាគីដែលចាប់យក', value: 'parties' },
        { text: 'ភ្ជាប់ភស្តុតាង', value: 'view' }
      ],
      decisionDocumentHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខលិខិត', value: 'decisionRef' },
        { text: 'កាលបរិច្ឆេទ', value: 'decisionDate' },
        { text: 'ប្រភេទការសម្រេច', value: 'decisionType' },
        { text: 'បណ្តឹងឧទ្ធរណ៍', value: 'isAppealed' },
        { text: 'កែប្រែ', value: 'actions' }
      ],
      taskListHeader: [
        { text: 'ល.រ', value: 'no' },
        { text: 'លេខឯកសារ', value: 'documentNo' },
        { text: 'កាលបរិច្ឆេទ', value: 'documentDate' },
        { text: 'ប្រភេទកិច្ចការ', value: 'taskType' },
        { text: 'ភាគីពាក់ព័ន្ធ', value: 'parties' },
        { text: 'កែប្រែ', value: 'actions' }
      ]
    }
  },
  async created() {
    this.rule = Rule

    if (this.$route.params.id) {
      var caseId = this.$route.params.id

      this.clerkName = JSON.parse(localStorage.getItem('user')).fullname

      await CaseService.getCaseById(caseId).then(response => {
        this.criminalCase = response.data
      })

      await CaseService.getPartyByCaseId(caseId).then(response => {
        this.relatedPersonList = response.data
      })

      await CaseService.getAppointmentByCaseId(caseId).then(
        response => {
          this.appointmentList = response.data
        },
        error => {
          alert(JSON.stringify(error.response.data))
        }
      )

      await CaseService.getWarrantByCaseId(caseId).then(response => {
        this.warrantList = response.data
      })

      await CaseService.getLawyerDocumentByCaseId(caseId).then(response => {
        this.documentList = response.data
      })

      await CaseService.getEvidenceDocumentByCaseId(caseId).then(response => {
        this.evidenceDocumentList = response.data
      })

      await CaseService.getDecisionDocumentByCaseId(caseId).then(response => {
        this.decisionDocumentList = response.data
      })

      await CaseService.getTaskDocumentByCaseId(caseId).then(response => {
        this.caseTaskList = response.data
      })

      await StaffService.getStaffListByPosition(2).then(response => {
        this.prosecutorList = response.data
      })
    }

    CaseService.getCasePartyRoleAll().then(response => {
      this.relatedPersonType = response.data
    })

    SystemService.getCaseLetterTypeAll().then(response => {
      this.caseLetterTypeList = response.data
    })

    SystemService.getCaseEvidenceTypeAll().then(response => {
      this.caseEvidenceTypeList = response.data
    })

    SystemService.getCaseDecisionType().then(response => {
      this.caseDecisionTypeList = response.data
    })

    SystemService.getCaseTaskTypeAll().then(response => {
      this.taskTypeList = response.data
      //  alert(JSON.stringify(this.taskTypeList ))
    })

    SystemService.getLawyerTypeAll().then(response => {
      this.caseLawyerTypes = response.data
    })
  },
  watch: {
    relatedPersonList: function(val) {
      this.$emit('updateProsData', val)
    }
  },
  methods: {
    create() {
      if (!this.$refs.form.validate()) return
      // return;

      if (Object.keys(this.appointment).length > 0) {
        this.isProsecutorAppointed = true
        this.appointmentDialog = false
      }
    },
    createAppointment() {
      if (!this.$refs.formAppointment.validate()) return

      if (this.criminalCase == null) return

      this.appointment.criminalData = this.criminalCase
      this.appointment.clerkName = this.clerkName

      CaseService.insertAppointment(this.appointment).then(
        response => {
          if (response.data) {
            this.resultMessage = 'Appointment has been assigned'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            this.appointmentList.push(this.appointment)
            this.appointment = {}
            this.appointmentDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    createWarrant() {
      if (!this.$refs.formWarrant.validate()) return
      if (this.criminalCase == null) return

      this.warrantData.criminalData = this.criminalCase
      this.warrantData.clerkName = this.clerkName

      CaseService.insertWarrant(this.warrantData).then(
        response => {
          if (response.data) {
            this.resultMessage = 'Warrant has been submitted'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            if (this.editedWarrantIndex > -1) {
              Object.assign(
                this.warrantList[this.editedWarrantIndex],
                this.warrantData
              )
            } else {
              this.warrantData.id = response.data.id
              this.warrantList.push(this.warrantData)
            }
            this.editedWarrantIndex = -1
            this.warrantData = {}
            this.warrantDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    editWarrant(item) {
      this.editedWarrantIndex = this.warrantList.indexOf(item)
      this.warrantData = Object.assign({}, item)
      this.warrantDialog = true
    },

    onWarrantDialogClose() {
      this.editedWarrantIndex = -1
      this.warrantData = {}
      this.warrantDialog = false
    },
    async onRelatedPartyClose() {
      if (this.$route.params.id) {
        //  alert('hi')
        var caseId = this.$route.params.id

        await CaseService.getPartyByCaseId(caseId).then(response => {
          this.relatedPersonList = response.data
        })
        this.relatedPersonListDialog = false
      }
    },
    createParties() {
      if (!this.$refs.formParties.validate()) return

      CaseService.insertPartyBatch(this.relatedPersonList).then(
        response => {
          if (response.data && response.data.message == 'created') {
            this.resultMessage = 'The party list has been updated'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })
            this.relatedPersonListDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    createDocument() {
      if (!this.$refs.formDocument.validate()) return
      if (this.criminalCase == null) return

      this.lawyerDocument.criminalData = this.criminalCase

      CaseService.insertLawyerDocument(this.lawyerDocument).then(
        response => {
          if (response.data) {
            this.resultMessage = 'Lawyer document has been submitted'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            this.lawyerData.lawyers = this.lawyerData.lawyers.map(v => ({
              ...v,
              caseLawyerDocument: response.data
            }))

            CaseService.insertLawyerDocumentAssignmentBatch(
              this.lawyerData.lawyers
            )

            if (this.editedDocumentIndex > -1) {
              Object.assign(
                this.documentList[this.editedDocumentIndex],
                this.lawyerDocument
              )
            } else {
              this.lawyerDocument.id = response.data.id
              this.documentList.push(this.lawyerDocument)
            }

            this.editedDocumentIndex = -1
            this.lawyerDocument = {}
            this.lawyerData.lawyers = []
            this.documentDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    async editDocument(item) {
      this.editedDocumentIndex = this.documentList.indexOf(item)
      this.lawyerDocument = Object.assign({}, item)
      var documentId = item.id

      await CaseService.getLawyerDocumentAssignmentByDocumentId(
        documentId
      ).then(response => {
        this.lawyerData.lawyers = response.data
      })
      this.documentDialog = true
    },
    onDocumentDialogClose() {
      this.editedDocumentIndex = -1
      this.lawyerDocument = {}
      this.lawyerData.lawyers = []
      this.documentDialog = false
    },
    createEvidenceDocument() {
      if (!this.$refs.formEvidenceList.validate()) return
      if (this.criminalCase == null) return

      this.evidenceDocument.criminalData = this.criminalCase

      CaseService.insertEvidenceDocument(this.evidenceDocument).then(
        response => {
          if (response.data) {
            this.resultMessage = 'Evidence document has been submitted'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            this.evidenceData.evidenceList = this.evidenceData.evidenceList.map(
              v => ({
                ...v,
                evidenceDocument: response.data
              })
            )

            CaseService.insertEvidenceDocumentItemsBatch(
              this.evidenceData.evidenceList
            )

            if (this.editedEvidenceDocumentIndex > -1) {
              Object.assign(
                this.evidenceDocumentList[this.editedEvidenceDocumentIndex],
                this.evidenceDocument
              )
            } else {
              this.evidenceDocument.id = response.data.id
              this.evidenceDocumentList.push(this.evidenceDocument)
            }

            this.editedEvidenceDocumentIndex = -1
            this.evidenceDocument = {}
            this.evidenceData.evidenceList = []
            this.evidenceListDialog = false
          }
        },
        error => {
          this.resultMessage = error.response
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    async editEvidence(item) {
      this.editedEvidenceDocumentIndex = this.evidenceDocumentList.indexOf(item)
      this.evidenceDocument = Object.assign({}, item)
      var evidenceId = item.id

      await CaseService.getEvidenceItemByDocumentId(evidenceId).then(
        response => {
          this.evidenceData.evidenceList = response.data
        }
      )

      this.evidenceListDialog = true
    },
    onEvidenceDialogClose() {
      this.editedEvidenceDocumentIndex = -1
      this.evidenceDocument = {}
      this.evidenceData.evidenceList = []
      this.evidenceListDialog = false
    },
    addEvidenceItem() {
      this.evidenceData.evidenceList.push({})
    },
    removeEvidenceItem(index) {
      this.$confirm({
        message: 'Do you want to delete this item: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.evidenceData.evidenceList.splice(index, 1)
          }
        }
      })
    },
    async viewLawyer(item) {
      var documentId = item.id

      await CaseService.getLawyerDocumentAssignmentByDocumentId(
        documentId
      ).then(response => {
        this.documentLawyerList = response.data
      })
      this.documentLawyerDialog = true
    },
    genTest: function() {
      this.prosecutionData = {
        letterNo: '111198827កខ',
        appointmentDate: '2024-01-05',
        prosecutorName: 'Ly Rithy',
        clerkName: 'Chhuy Yarttak'
      }
    },
    getFieldText(item) {
      return `${item.nameKh}`
    },
    getCaseLetterTypeByTypeId(id) {
      return this.caseLetterTypeList.filter(function(elem) {
        if (elem.letterType.id === id) return elem
      })
    },
    addLawyerItem() {
      this.lawyerData.lawyers.push({})
    },
    removeLawyerItem(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.lawyerData.lawyers.splice(index, 1)
          }
        }
      })
    },
    addRelatedPerson(event) {
      this.relatedPersonList.push({
        caseId: this.criminalCase.id,
        partyRole: {}
      })
    },

    removeRelatedPerson(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.relatedPersonList.splice(index, 1)
          }
        }
      })
    },

    async viewEvidence(item) {
      await CaseService.getEvidenceItemByDocumentId(item.id).then(response => {
        if (response.data) {
          this.evidenceItems = response.data
          this.evidenceItemDialog = true
        }
      })
    },

    addChargePersonItem() {
      this.chargePersonList.push({ party: {}, crimeType: [] })
    },
    openChargeDialog() {
      this.chargeDialog = true

      SystemService.getCaseCrimeTypeAll().then(response => {
        this.caseCrimeTypeList = response.data
      })
    },

    removeChargePersonItem(index) {
      this.$confirm({
        message: 'Do you want to delete this person: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.chargePersonList.splice(index, 1)
          }
        }
      })
    },
    createTask() {
      if (!this.$refs.formTaskList.validate()) return
      if (this.criminalCase == null) return

      this.taskDocument.criminalData = this.criminalCase

      CaseService.insertTaskDocument(this.taskDocument).then(
        response => {
          if (response.data) {
            this.resultMessage = this.$t('message.addedData')
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            if (this.editedTaskIndex > -1) {
              Object.assign(
                this.caseTaskList[this.editedTaskIndex],
                this.taskDocument
              )
            } else {
              this.taskDocument.id = response.data.id
              this.caseTaskList.push(this.taskDocument)
            }

            this.taskDocument = {}
            this.editedTaskIndex = -1
            this.taskDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },

    createDecision() {
      if (!this.$refs.formDecisionDocumentList.validate()) return
      if (this.criminalCase == null) return

      this.decisionDocument.criminalData = this.criminalCase

      CaseService.insertDecisionDocument(this.decisionDocument).then(
        response => {
          if (response.data) {
            this.resultMessage = 'Decision has been submitted'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            var judgementList = []

            this.chargePersonList.forEach((item, index) => {
              item.crimeType.forEach(t => {
                judgementList.push({
                  decisions: response.data,
                  party: item.party,
                  crimeType: t
                })
              })
            })

            if (judgementList.length > 0) {
              CaseService.insertDecisionJudgement(judgementList).then(
                response => {
                  this.resultMessage = 'Judegements have been added'
                  this.$notify({
                    group: 'message',
                    title: 'Notification',
                    type: 'success',
                    text: this.resultMessage
                  })
                }
              )
            }

            if (this.editedDecisionIndex > -1) {
              Object.assign(
                this.decisionDocumentList[this.editedDecisionIndex],
                this.decisionDocument
              )
            } else {
              this.decisionDocument.id = response.data.id
              this.decisionDocumentList.push(this.decisionDocument)
            }

            this.decisionDocument = {}
            this.decisionDocumentDialog = false
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    editTaskDocument(item) {
      this.editedTaskIndex = this.caseTaskList.indexOf(item)
      this.taskDocument = Object.assign({}, item)
      this.taskDialog = true
    },
    editDecisionDocument(item) {
      CaseService.getJudgementByDecisionId(item.id).then(response => {
        var judgementList = response.data
        this.chargePersonList = []
        judgementList.forEach(item => {
          this.chargePersonList.push({
            party: item.party,
            crimeType: item.crimeType
          })
        })
      })

      this.editedDecisionIndex = this.decisionDocumentList.indexOf(item)
      this.decisionDocument = Object.assign({}, item)
      this.decisionDocumentDialog = true
    },
    onDecisionDocumentDialogClose() {
      this.editedDecisionIndex = -1
      this.decisionDocument = {}
      this.chargePersonList = []
      this.decisionDocumentDialog = false
    },
    onTaskDialogClose() {
      this.editedTaskIndex = -1
      this.taskDocument = {}
      this.taskDialog = false
    },
    onViewJudgementDialogClose() {
      this.viewJudgementDialog = false
      this.chargePersonList = []
    },
    async showJudgement(item) {
      await CaseService.getJudgementByDecisionId(item.id).then(response => {
        var judgementList = response.data

        this.chargePersonList = []
        judgementList.forEach(item => {
          this.chargePersonList.push({
            party: item.party,
            crimeType: item.crimeType
          })
        })
        this.viewJudgementDialog = true
      })
    }
  }
}
</script>
<style scoped>
.right-side-dialog {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 600px;
  width: 100%;
  overflow-y: auto;
}

.right-side-dialog-800 {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 800px;
  width: 100%;
  overflow-y: auto;
}

.slide-x-transition-enter-active,
.slide-x-transition-leave-active {
  transition: transform 0.3s ease;
}

.slide-x-transition-enter {
  transform: translateX(100%);
}

.slide-x-transition-leave-to {
  transform: translateX(100%);
}
</style>